import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GangsOfGamers from '../components/GangsOfGamers/GangsOfGamers'
import {
  generateFaqSchema,
  generateGangsOfGamersVideoSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import defaultFaqs from '../components/FAQs/faqs-list'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Gangs Of Gamers',
    url: '/gangs-of-gamers',
  },
]

const GangsOfGamersContainer: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Gangs Of Gamers App APK Download: Rummy, Carrom Board, Poker & More"
        description="Read about the Gangs of Gamers Facebook Page managed by Mega"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(defaultFaqs)}
        videoSchema={generateGangsOfGamersVideoSchema()}
      />
      <GangsOfGamers />
    </Layout>
  )
}

export default GangsOfGamersContainer
