import React, { useEffect, useState } from 'react'
import './GangsOfGamers.scss'
import Container from 'react-bootstrap/esm/Container'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import TestimonialsContainer from '../Index/Testimonials/TestimonialsContainer'
import HomeFAQs from '../Index/HomeFAQs/HomeFAQs'
import { Faq } from '../FAQs/faqs-list-interface'
import useTestimonials from '../Testimonials/useTestimonials'
import PopupDownload from '../PopupDownload/PopupDownload'
import LinkButton from '../LinkButton/LinkButton'
import gameDescription from '../Games/games-description'
import * as links from '../internal-links'
import DesktopSmall from '../Games/GameCard/DesktopSmall'
import useShowAll from '../../hooks/useShowAll'
import GameCard from '../Games/GameCard/GameCard'

const GangsOfGamers: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "casual-intro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      Poker: file(
        relativePath: { eq: "Desktop Recommendation assets/poker-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      PokerM: file(
        relativePath: { eq: "Mobile Poster Assets/TEXAS_YOUTUBE Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      Rummy: file(
        relativePath: { eq: "Desktop Recommendation assets/rummy-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      RummyM: file(
        relativePath: { eq: "Mobile Poster Assets/preview copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Carrom: file(
        relativePath: { eq: "Desktop Recommendation assets/Mask Copy 3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      CarromM: file(
        relativePath: { eq: "Mobile Poster Assets/iPoster 2 Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GoPool: file(
        relativePath: {
          eq: "Desktop Recommendation assets/GoPool_iPoster Copy 4.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GoPoolM: file(
        relativePath: { eq: "Mobile Poster Assets/GoPool_iPoster Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      DotsNDash: file(
        relativePath: { eq: "Desktop poster assets/iPoster 2 Copy 9.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      DotsNDashM: file(
        relativePath: { eq: "Mobile Poster Assets/iPoster 2 Copy 10.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      ABCRummy: file(
        relativePath: {
          eq: "Desktop Recommendation assets/iPoster 2 Copy ui Copy.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      ABCRummyM: file(
        relativePath: {
          eq: "Mobile Poster Assets/iPoster_ABC_Rummy Copy 2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Warship: file(
        relativePath: { eq: "Desktop poster assets/Warship_1440x2880.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      WarshipM: file(
        relativePath: {
          eq: "Mobile Poster Assets/Warship_Iposter 392x448 Copy 2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      FruitKaat: file(
        relativePath: { eq: "Desktop Recommendation assets/Group 65.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      FruitKaatM: file(
        relativePath: { eq: "Mobile Poster Assets/squarer.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GK: file(
        relativePath: {
          eq: "Desktop Recommendation assets/GK_Guru_Vertical.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      GKM: file(
        relativePath: {
          eq: "Mobile Poster Assets/GK_Guru_Horizontal_12 Copy 3.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      One23: file(
        relativePath: { eq: "Desktop Recommendation assets/iPoster Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      One23M: file(relativePath: { eq: "Mobile Poster Assets/iPoster.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      PicMe: file(
        relativePath: { eq: "Desktop poster assets/picme1440x2880 Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      PicMeM: file(
        relativePath: { eq: "Mobile Poster Assets/PicMe_Horizontal Copy 3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meme1: file(relativePath: { eq: "gangs-of-gamers/meme1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
      meme2: file(relativePath: { eq: "gangs-of-gamers/meme2.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
      meme3: file(relativePath: { eq: "gangs-of-gamers/meme3.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
      meme4: file(relativePath: { eq: "gangs-of-gamers/meme4.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [gameFaqsSt, setGameFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../FAQs/faqs-list').then(({ gameFaqs }) => setGameFaqsSt(gameFaqs))
  }, [])

  const { allGamesTestimonials } = useTestimonials()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const isDesktop = useShowAll()

  return (
    <div id="GangsOfGamers">
      <div className="header">
        <Container>
          <h1 className="page-title">Gangs of Gamers</h1>
          <h3>
            Gangs of Gamerz is a Facebook Page managed by Mega.
            <br />
            The Facebook page has 6,000+ likes and is followed by more than{' '}
            <span className="underline">14,200+</span> users.
          </h3>
          <p>
            The Facebook page caters to Gamers who want to earn money by playing
            skill based games. The community of 14,200+ users interact on the
            Facebook page and play games together on Mega.
          </p>
        </Container>
      </div>
      <div className="content">
        <Container>
          <h3>Play with 10,000+ players on real money</h3>
          <p>
            You can also play with friends & family on video-chat with real
            money. Sounds fun, doesn&apos;t it?
          </p>
          <LinkButton
            className="popBtn inline-btn download-btn"
            onClick={() => {
              setIsPopupOpen(true)
            }}
            color="white"
            width="200px"
          >
            Download App Now
          </LinkButton>
        </Container>
      </div>
      <div className="content-black">
        <Container>
          <h3>Get frequent updates of funny memes and interesting videos.</h3>
          <div className="meme-cards">
            <div className="meme-card">
              <GatsbyImage
                image={data.meme1.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="meme"
              />
            </div>
            <div className="meme-card">
              <GatsbyImage
                image={data.meme2.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="meme"
              />
            </div>
            <div className="meme-card">
              <GatsbyImage
                image={data.meme3.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="meme"
              />
            </div>
            <div className="meme-card">
              <GatsbyImage
                image={data.meme4.childImageSharp.gatsbyImageData}
                loading="eager"
                alt="meme"
              />
            </div>
          </div>
          <h3>Mega has 2 games on the app.</h3>
          <p>
            Mega is the only Real Money Gaming Platform that allows you to play
            games with video chat. Mega has 2 games on the app. The games are
            divisible into:
          </p>
          <h3 className="cards-title">Card Games</h3>
          {!isDesktop && (
            <div className="m-cards">
              <div className="m-card">
                <GameCard
                  category="cards"
                  showCategory
                  name="Hold 'em Poker"
                  showName
                  description={gameDescription.Poker.long}
                  showDescription
                  gameImage={data.PokerM.childImageSharp.gatsbyImageData}
                  gameUrl={links.POKER}
                />
              </div>
              <div className="m-card">
                <GameCard
                  category="cards"
                  showCategory
                  name="Rummy"
                  showName
                  description={gameDescription.Rummy.long}
                  showDescription
                  gameImage={data.RummyM.childImageSharp.gatsbyImageData}
                  gameUrl={links.RUMMY}
                />
              </div>
            </div>
          )}
          {isDesktop && (
            <div className="d-cards">
              <div className="d-card">
                <DesktopSmall
                  category="cards"
                  showCategory
                  name="Hold 'em Poker"
                  showName
                  description={gameDescription.Poker.short}
                  showDescription
                  gameImage={data.Poker.childImageSharp.gatsbyImageData}
                  gameUrl={links.POKER}
                  detailsBackground="#0E606C"
                />
              </div>
              <div className="d-card">
                <DesktopSmall
                  category="cards"
                  showCategory
                  name="Rummy"
                  showName
                  description={gameDescription.Rummy.short}
                  showDescription
                  gameImage={data.Rummy.childImageSharp.gatsbyImageData}
                  gameUrl={links.RUMMY}
                  detailsBackground="#0E606C"
                />
              </div>
            </div>
          )}
        </Container>
      </div>
      <div className="content">
        <Container>
          <h3>Download the app now and play with 10,000+ Players Daily</h3>
          <LinkButton
            className="popBtn inline-btn download-btn"
            onClick={() => {
              setIsPopupOpen(true)
            }}
            color="white"
            width="200px"
          >
            Download App
          </LinkButton>
        </Container>
      </div>
      <TestimonialsContainer testimonials={allGamesTestimonials} />
      <div className="games-faqs">
        <HomeFAQs faqs={gameFaqsSt} />
      </div>
      {isPopupOpen && (
        <PopupDownload
          close={() => {
            setIsPopupOpen(false)
          }}
          gameName="Poker"
        />
      )}
    </div>
  )
}

export default GangsOfGamers
